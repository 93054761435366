import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faGraduationCap,
  faBook,
  faUser,
  faPaw,
} from "@fortawesome/free-solid-svg-icons";
import "../styles/header.css";
import { Tooltip } from "react-tooltip";

const MENU_ITEMS = [
  { link: "https://statcamp.ch/index.php", tooltip: "Home", icon: faHouse },
  {
    link: "https://statcamp.ch/nachhilfe.php",
    tooltip: "Nachhilfe",
    icon: faGraduationCap,
  },
  { link: "/", tooltip: "Wiki", icon: faBook },
  {
    link: "https://mio.statcamp.ch/",
    tooltip: "Mio (experimental)",
    icon: faPaw,
  },
  { link: "https://statcamp.ch/user.php", tooltip: "User", icon: faUser },
];

function Header(props) {
  const { backgroundColor, ui, text, hover, bright } = props;
  const [menuHovered, setmenuHovered] = useState(false);

  const handleMouseEnter = () => {
    setmenuHovered(true);
  };

  const handleMouseLeave = () => {
    setmenuHovered(false);
  };

  const style = {
    "--ui-color": ui,
    "--text-color": text,
    "--background-color": backgroundColor,
    "--hover-color": hover,
    "--bright-color": bright,
    "--icon-color": text, // set the color of the icon to text
    "--icon-hover-color": bright, // set the hover color of the icon to bright
  };

  return (
    <div class="menu-container" style={style}>
      <Tooltip
        id="my-tooltip"
        style={{
          padding: "0.3rem",
          paddingLeft: "0.5rem",
          paddingRight: "0.5rem",
          marginTop: "7px",
          background: ui,
          color: text,
        }}
      />
      {MENU_ITEMS.map((item) => (
        <a key={item.link} href={item.link}>
          <div
            class="menu-tile"
            data-tooltip-id="my-tooltip"
            data-tooltip-content={item.tooltip}
            style={{
              marginTop: "1rem",
              height: "70px",
              width: "70px",
              background: ui,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <FontAwesomeIcon id="icon" icon={item.icon} />
          </div>
        </a>
      ))}
    </div>
  );
}

export default Header;
