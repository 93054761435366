import React from "react";
import { useState } from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LightModeIcon from "@mui/icons-material/LightMode";

function AirbnbThumbComponent(props) {
  const { children, text, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <LightModeIcon style={{ fontSize: "3rem", color: text }} />
    </SliderThumb>
  );
}

const AirbnbSlider = styled(Slider)(({}) => ({
  color: "white",
  "& .MuiSlider-thumb": {
    height: 1,
    width: 1,
    backgroundColor: "transparent",
  },
  "& .MuiSlider-rail": {
    color: "white",
    height: 150,
  },
}));

export default function Modus(props) {
  const { onSliderChange, onColorChange, colorNames, text } = props;
  const [colorIndex, setColorIndex] = useState(2); // set the default color index to 2 (blue)

  const handleChange = (event, value) => {
    setColorIndex(value - 1); // subtract 1 to get the index of the color in the array
    const selectedColor = colorNames[value - 1];
    onColorChange(selectedColor);
  };

  return (
    <div
      style={{
        position: "absolute",
        background:
          "linear-gradient(to top, rgb(17, 17, 17), rgb(177, 177, 177))",
        borderRadius: "50px",
        padding: "0.5rem",
        paddingBottom: "1.5rem",
        paddingTop: "1.5rem",
        zIndex: "6",
        border: "2px solid",
        borderColor: text,
      }}
    >
      <Box sx={{ width: 50, height: 150, padding: "0.5rem" }}>
        <AirbnbSlider
          slots={{ thumb: AirbnbThumbComponent }}
          aria-label="custom thumb label"
          defaultValue={[2]}
          orientation="vertical"
          step={1}
          min={1}
          max={4}
          onChange={handleChange}
        />
      </Box>
    </div>
  );
}

//linear-gradient(to top, rgb(99, 99, 99), rgb(36, 36, 36))
