import { useState } from "react";
import "../styles/category.css";

function Categories({
  categories,
  onCategoryClick,
  backgroundColor,
  ui,
  text,
  hover,
}) {
  const [activeCategory, setActiveCategory] = useState(null);
  const uniqueCategories = categories.filter((category, index, self) => {
    return index === self.findIndex((c) => c.name === category.name);
  });

  const colorClick = hover;
  const colorAfter = backgroundColor;

  return (
    <div
      style={{
        zIndex: "2",
        background: backgroundColor,
        backdropFilter: "blur(10px)",
        borderRadius: "35px",
        color: text,
        flexDirection: "column",
        cursor: "pointer",
        transition: "0.25s ease-in-out",
        border: "4px solid",
        borderColor: ui,
      }}
    >
      <div
        style={{
          backgroundColor: ui,
          height: "55px",
          borderTopLeftRadius: "25px",
          borderTopRightRadius: "25px",
          zIndex: "5",
          transition: "0.25s ease-in-out",
          padding: "1rem",
        }}
      >
        <h2
          style={{
            marginBottom: "0.5rem",
            fontWeight: "bold",
            fontSize: "large",
          }}
        >
          Kategorien Filter
        </h2>
      </div>
      <ul
        style={{
          listStyleType: "none",
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <li
          id="catstyle"
          style={{
            cursor: "pointer",
            background: colorAfter,
            borderRadius: "10px",
            padding: "0.5rem",
            transition: "0.2s ease-in-out",
          }}
          onClick={(event) => {
            setActiveCategory(null);
            onCategoryClick(" ");
            event.target.style.background = colorClick;
            setTimeout(() => {
              event.target.style.background = colorAfter;
              setActiveCategory(null);
            }, 500);
          }}
        >
          Alle
        </li>

        {uniqueCategories.map((category) => (
          <li
            style={{
              cursor: "pointer",
              background: colorAfter,
              borderRadius: "10px",
              padding: "0.5rem",
              transition: "0.25s ease-in-out",
            }}
            id="catstyle"
            onClick={(event) => {
              setActiveCategory(category);
              onCategoryClick(category.name);
              event.target.style.background = colorClick;
              setTimeout(() => {
                event.target.style.background = colorAfter;
                setActiveCategory(null);
              }, 500);
            }}
          >
            {category.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Categories;
