import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

function ArticleCard({ post, backgroundColor, ui, text, hover }) {
  const { title, summary, categories, featuredImage } = post;
  const summarySentences = post.summary.split(".");
  const firstSentence = summarySentences[0];
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{
        zIndex: "2",
        maxWidth: "350px",
        transition: "0.4s ease-in-out",
        background: isHovered ? hover : ui,
        backdropFilter: "blur(5px)",
        padding: "1.25rem",
        cursor: "pointer",
        borderRadius: "50px",
        color: text,
        boxShadow: "0 10px 10px rgba(0, 0, 0, 0.25)",
      }}
      onClick={() => navigate(`/${post.slug}`)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <h2 style={{ fontWeight: "bold", padding: "0.25rem" }}>{title}</h2>
      <img
        src={featuredImage.url}
        alt={title}
        style={{
          borderRadius: "25px",
          marginTop: "0.5rem",
          marginBottom: "1rem",
        }}
      />
      <p
        style={{
          marginBottom: "1rem",
        }}
      >
        {firstSentence}.
      </p>
      <div style={{ display: "inline-block", height: "2rem" }}>
        <ul
          style={{
            position: "absolute",
            bottom: "1rem",
            left: "1rem",
            display: "flex",
            flexWrap: "wrap",
            margin: 0,
            padding: 0,
            listStyle: "none",
          }}
        >
          {categories.map((category) => (
            <li
              key={category.slug}
              style={{
                marginRight: "8px",
                marginBottom: "8px",
                padding: "4px 8px",
                borderRadius: "15px",
                background: backgroundColor,
                border: "2px solid",
                borderColor: ui,
                fontSize: "14px",
                fontWeight: "bold",
                boxShadow:
                  "0 0 4px rgba(0, 0, 0, 0.3), 0 4px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              {category.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ArticleCard;
