import { useState, useEffect } from "react";
import { GraphQLClient, gql } from "graphql-request";
import { useNavigate } from "react-router-dom";

const graphQLClient = new GraphQLClient(
  "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cleoofwt51pts01ude7tl0lqe/master"
);

const QUERY = gql`
  {
    news {
      body
      id
      title
      date
    }
  }
`;

function News({ backgroundColor, ui, text, hover }) {
  const [newsData, setNewsData] = useState([]);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    graphQLClient.request(QUERY).then((data) => {
      const sortedData = data.news.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      setNewsData(sortedData);
    });
  }, []);

  return (
    <div
      style={{
        zIndex: "2",
        background: backgroundColor,
        backdropFilter: "blur(10px)",
        borderRadius: "35px",
        color: text,
        flexDirection: "column",
        cursor: "pointer",
        transition: "0.4s ease-in-out",
        border: "4px solid",
        borderColor: isHovered ? hover : ui,
      }}
      onClick={() => navigate("/news")}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          backgroundColor: isHovered ? hover : ui,
          height: "55px",
          borderTopLeftRadius: "25px",
          borderTopRightRadius: "25px",
          zIndex: "5",
          transition: "0.4s ease-in-out",
          padding: "1rem",
        }}
      >
        <h2
          style={{
            fontWeight: "bold",
            fontSize: "large",
          }}
        >
          News
        </h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem",
        }}
      >
        {newsData.map((post) => (
          <div
            key={post.id}
            style={{
              zIndex: "3",
              background: backgroundColor,
              backdropFilter: "blur(5px)",
              padding: "1rem",
              borderRadius: "25px",
              color: text,
              flexDirection: "column",

              boxShadow: "0 10px 10px rgba(0, 0, 0, 0.25)",
            }}
          >
            <h3 style={{ fontWeight: "bold" }}>{post.title}</h3>
            <p style={{ marginBottom: "0.5rem", fontStyle: "italic" }}>
              {new Date(post.date)
                .toLocaleDateString("de-DE", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
                .replace(/\./g, "/")}
              , um{" "}
              {new Date(post.date).toLocaleTimeString("de-DE", {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              Uhr
            </p>

            <p>{post.body}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default News;
