import React from "react";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import bgCamp from "./comps/background.jpg";
import Home from "./Home";
import NewsFeed from "./Newsfeed";
import Header from "./components/Header";
import WikiPost from "./components/WikiPost";
import Modus from "./components/Modus";

function App() {
  const [sliderValue, setSliderValue] = useState(0);
  const [colorName, setColorName] = useState("dark");
  const colorNames = ["verydark", "dark", "light", "verylight"];

  const colors = {
    verylight: {
      background:
        "linear-gradient(to top left, rgb(188, 188, 188, 0.4), rgb(197, 197, 197, 0.4))",
      text: "rgb(17, 17, 17)",
      ui: "rgb(189, 189, 189)",
      hover: "rgb(240, 240, 240)",
      hoverBright: "white",
    },
    light: {
      background:
        "linear-gradient(to top left, rgb(98, 98, 98, 0.2), rgb(119, 119, 119, 0.2))",
      text: "rgb(219, 219, 219)",
      ui: "rgb(101, 101, 101)",
      hover: "rgb(159, 159, 159)",
      hoverBright: "white",
    },
    dark: {
      background:
        "linear-gradient(to top left, rgb(53, 53, 53, 0.2), rgb(81, 81, 81, 0.2))",
      text: "rgb(209, 209, 209)",
      ui: "rgb(58, 58, 58)",
      hover: "rgb(131, 131, 131)",
      hoverBright: "white",
    },
    verydark: {
      background:
        "linear-gradient(to top left, rgb(31, 31, 31, 0.2), rgb(61, 61, 61, 0.2))",
      text: "#CCCCCC",
      ui: "rgb(36, 36, 36)",
      hover: "rgb(117, 117, 117)",
      hoverBright: "white",
    },
  };

  const currentColors = colors[colorName];

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        backgroundImage: `url(${bgCamp})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: -1,
      }}
    >
      <div style={{ padding: "50px" }}>
        <div
          style={{
            position: "absolute",
            top: "1rem",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Header
            backgroundColor={currentColors.background}
            ui={currentColors.ui}
            text={currentColors.text}
            hover={currentColors.hover}
            bright={currentColors.hoverBright}
          />
        </div>
        <div style={{ position: "absolute", height: "100%" }}>
          <Modus
            onSliderChange={(value) => setSliderValue(value)}
            onColorChange={(name) => setColorName(name)}
            colorNames={colorNames}
            text={currentColors.text}
          />
        </div>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                backgroundColor={currentColors.background}
                ui={currentColors.ui}
                text={currentColors.text}
                hover={currentColors.hover}
              />
            }
          />
          <Route
            path="/news"
            element={
              <NewsFeed
                backgroundColor={currentColors.background}
                ui={currentColors.ui}
                text={currentColors.text}
                hover={currentColors.hover}
                bright={currentColors.hoverBright}
              />
            }
          />
          <Route
            path="/:slug"
            element={
              <WikiPost
                backgroundColor={currentColors.background}
                ui={currentColors.ui}
                text={currentColors.text}
                hover={currentColors.hover}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
