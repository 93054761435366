import { useParams } from "react-router-dom";
import { GraphQLClient, gql } from "graphql-request";
import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Button from "./button";

const graphQLClient = new GraphQLClient(
  "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cleoofwt51pts01ude7tl0lqe/master"
);

const QUERY = gql`
  {
    wikiPosts {
      createdAt
      id
      publishedAt
      slug
      summary
      title
      content {
        html
      }
      updatedAt
      categories {
        name
        slug
      }
      featuredImage {
        url
      }
      video {
        html
      }
    }
  }
`;

let widthResponsive = "85vw";

const updateWidthResponsive = () => {
  widthResponsive = "85vw";
};

window.addEventListener("resize", updateWidthResponsive);

export { widthResponsive };

function WikiPost({ backgroundColor, ui, text, hover, bright }) {
  const [post, setPost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    async function fetchPosts() {
      const { wikiPosts } = await graphQLClient.request(QUERY);
      const matchingPost = wikiPosts.find((post) => post.slug === slug);
      setPost(matchingPost);
    }
    fetchPosts();
  }, [slug]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div
        style={{
          position: "absolute",
          zIndex: "2",
          width: widthResponsive,
          maxWidth: "1050px",
          top: "15%",
          left: "50%",
          transform: "translateX(-50%)",
          background: backgroundColor,
          backdropFilter: "blur(10px)",
          borderRadius: "35px",
          color: text,
          flexDirection: "column",
          border: "4px solid",
          borderColor: ui,
        }}
      >
        <div
          style={{
            backgroundColor: ui,
            height: "55px",
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
            zIndex: "5",
            padding: "1rem",
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: "5",
              top: "0rem",
              right: "3rem",
            }}
          >
            <Button
              backgroundColor={backgroundColor}
              ui={ui}
              text={text}
              hover={hover}
              bright={bright}
            />
          </div>
          <h2
            style={{
              position: "absolute",
              fontWeight: "bold",
              fontSize: "larger",
              left: "2rem",
            }}
          >
            {post.title}
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem",
          }}
        >
          <div
            style={{
              zIndex: "3",
              background: backgroundColor,
              backdropFilter: "blur(5px)",
              padding: "1rem",
              borderRadius: "25px",
              color: text,
              flexDirection: "column",

              boxShadow: "0 10px 10px rgba(0, 0, 0, 0.25)",
            }}
          >
            <h3
              style={{
                fontWeight: "bold",
                fontSize: "medium",
              }}
            >
              Kurz & knackig
            </h3>
            <p>{post.summary}</p>
          </div>
          <div
            style={{
              zIndex: "3",
              background: backgroundColor,
              backdropFilter: "blur(5px)",
              padding: "1rem",
              borderRadius: "25px",
              color: text,
              flexDirection: "column",
              boxShadow: "0 10px 10px rgba(0, 0, 0, 0.25)",
            }}
          >
            <h3
              style={{
                fontWeight: "bold",
                fontSize: "medium",
              }}
            >
              Um was geht es genau?
            </h3>
            <div dangerouslySetInnerHTML={{ __html: post.content.html }} />
          </div>

          {post.video && (
            <div
              style={{
                zIndex: "3",
                background: backgroundColor,
                backdropFilter: "blur(5px)",
                padding: "1rem",
                borderRadius: "25px",
                color: text,
                flexDirection: "column",
                boxShadow: "0 10px 10px rgba(0, 0, 0, 0.25)",
                height: `calc((${widthResponsive} * 9 / 16) - 0.5rem)`,
                maxHeight: `calc((1050px * 9 / 16) - 0.5rem)`,
              }}
            >
              <ReactPlayer
                url={post.video.html}
                width="100%"
                height="100%"
                controls="true"
              />
            </div>
          )}
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default WikiPost;
