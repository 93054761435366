import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { Cross } from "@styled-icons/entypo/Cross";

function Button({ backgroundColor, ui, text, hover, bright }) {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }

  return (
    <div
      style={{
        width: "50px",
        height: "50px",
        opacity: 1,
        border: "1px solid",
        borderColor: isHovered ? bright : text,
        borderRadius: "50%",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: backgroundColor,
        backdropFilter: "blur(10px)",
        cursor: "pointer",
        color: isHovered ? bright : text,
        scale: isHovered ? "1.2" : "1",
        transition: "0.25s ease-in-out",
      }}
      onClick={handleClick}
      whileTap={{
        scale: 0.8,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isHovered={isHovered}
    >
      <Cross width="30px" height="30px" />
    </div>
  );
}

export default Button;
