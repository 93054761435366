import { GraphQLClient, gql } from "graphql-request";
import { useState, useEffect } from "react";
import ArticleCard from "./components/ArticleCard";
import News from "./components/News";
import Categories from "./components/Categories";
import SearchBar from "./components/SearchBar";
import { BackgroundColor } from "styled-icons/foundation";

const graphQLClient = new GraphQLClient(
  "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cleoofwt51pts01ude7tl0lqe/master"
);

const QUERY = gql`
  {
    wikiPosts(first: 500) {
      createdAt
      id
      publishedAt
      slug
      summary
      title
      content {
        html
      }
      updatedAt
      categories {
        name
        slug
      }
      featuredImage {
        url
      }
    }
  }
`;

const isMobile = window.innerHeight > window.innerWidth;

function filterPosts(posts, searchText) {
  return posts
    .map((post) => {
      return {
        ...post,
        priority: post.title.toLowerCase().includes(searchText.toLowerCase())
          ? 1
          : post.categories.some((category) =>
              category.name.toLowerCase().includes(searchText.toLowerCase())
            )
          ? 2
          : post.summary.toLowerCase().includes(searchText.toLowerCase())
          ? 3
          : 4,
      };
    })
    .filter((post) => {
      // Check if the post title, category, or summary includes the search text
      return (
        post.priority < 4 &&
        (post.title.toLowerCase().includes(searchText.toLowerCase()) ||
          post.categories.some((category) =>
            category.name.toLowerCase().includes(searchText.toLowerCase())
          ) ||
          post.summary.toLowerCase().includes(searchText.toLowerCase()))
      );
    })
    .sort((a, b) => {
      return a.priority - b.priority;
    });
}

function Home(props) {
  const [posts, setPosts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const { backgroundColor, ui, text, hover } = props;

  // Declare categories state and set it to an empty array
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function fetchPosts() {
      const { wikiPosts } = await graphQLClient.request(QUERY);
      const filteredPosts = filterPosts(wikiPosts, searchText);
      setPosts(filteredPosts);

      const allCategories = wikiPosts.flatMap((post) => post.categories);
      const uniqueCategories = Array.from(new Set(allCategories));
      // Set categories state to uniqueCategories
      setCategories(uniqueCategories);
    }
    fetchPosts();
  }, [searchText]);

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const handleCategorySelect = (categoryName) => {
    setSelectedCategory(categoryName);
    setSearchText(categoryName); // Update the search text with the selected category name
    console.log("Selected category:", categoryName);
  };

  return (
    <div>
      <div
        style={{
          position: "absolute",
          width: "90vw",
          left: "50%",
          transform: "translateX(-50%)",
          top: "20%",
          display: "flex",
          flexDirection: "row",
          gap: "2rem",
        }}
      >
        <div
          style={{
            flexGrow: isMobile ? "1" : "2",
            gap: "1rem",
            background: backgroundColor,
            backdropFilter: "blur(10px)",
            borderRadius: "35px",
            border: "4px solid",
            borderColor: ui,
          }}
        >
          <SearchBar
            onSearch={handleSearch}
            backgroundColor={backgroundColor}
            ui={ui}
            text={text}
          />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gridGap: "1rem",
              padding: "1rem",
              justifyContent: "space-evenly",
              justifyItems: "center",
            }}
          >
            {posts.map((post) => (
              <ArticleCard
                post={post}
                key={post.title}
                backgroundColor={backgroundColor}
                ui={ui}
                text={text}
                hover={hover}
              />
            ))}
          </div>
        </div>
        {!isMobile && (
          <div
            style={{
              flex: 1,
              minWidth: "250px",
              maxWidth: "300px",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <News
              backgroundColor={backgroundColor}
              ui={ui}
              text={text}
              hover={hover}
            />
            <Categories
              categories={categories}
              onCategoryClick={handleCategorySelect}
              searchText={searchText}
              backgroundColor={backgroundColor}
              ui={ui}
              text={text}
              hover={hover}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
