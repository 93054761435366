function SearchBar(props) {
  const { onSearch, backgroundColor, ui, text } = props;
  const handleInputChange = (event) => {
    const searchText = event.target.value;
    onSearch(searchText);
  };

  return (
    <div
      style={{
        zIndex: "2",
        height: "55px",
        display: "flex",
        alignItems: "center",
        padding: "1rem",
        background: ui,
        backdropFilter: "blur(5px)",
        borderTopLeftRadius: "25px",
        borderTopRightRadius: "25px",
        color: text,
      }}
    >
      <label
        htmlFor="search-input"
        style={{
          marginRight: "1rem",
          marginLeft: "1rem",
          fontSize: "medium",
          fontWeight: "bold",
        }}
      >
        Suchen:
      </label>
      <input
        type="text"
        onChange={handleInputChange}
        id="search-input"
        placeholder="Gib ein Schlagwort ein..."
        style={{
          border: "none",
          padding: "5px",
          outline: "none",
          marginRight: "1rem",
          flexGrow: 1,
          borderRadius: "15px",
          color: "black",
          backgroundColor: "white",
        }}
      />
    </div>
  );
}
export default SearchBar;
